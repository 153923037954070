@import "~antd/dist/antd.css";

@font-face {
  font-family: "Uomo";
  src: url("font/Uomo.woff2") format("woff2"),
    url("font/Uomo.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #c4aa70;
  font-family: "Calibri", sans-serif;
  overflow: hidden;
}

p {
  font-family: "Calibri", sans-serif;
  text-align: left;
  width: 100%;
  font-size: 1em;
  font-weight: 400;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

.ant-result .ant-result-icon {
  display: flex;
  justify-content: center;
}

.ant-result .ant-result-subtitle {
  font-size: 1.1rem;
}

.ant-checkbox-wrapper span {
  text-align: justify;
  hyphens: auto;
}

.fade-out {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.3s;
}

.fade-in {
  opacity: 1;
  width: 100%;
  height: auto;
  transition: width 0.1s, height 0.1s, opacity 0.4s 0.1s;
}

h1 {
  font-family: "Uomo", sans-serif;
  font-weight: 800;
  width: 100%;
}

h2,
h3 {
  font-family: "Calibri", sans-serif;
  font-weight: 400;
  width: 100%;
  text-align: justify;
  hyphens: auto;
}

h1 {
  text-align: center;
  font-size: 1.9em;
}

h2 {
  text-align: center;
  font-size: 1.4em;
  margin: 18px 0;
}

.App {
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-switcher {
  position: absolute;
  z-index: 999;
  top: 10px;
  right: 10px;
}

.main-container {
  display: flex;
  width: 90vw;
  max-width: 1100px;
  overflow: hidden;
  height: 90vh;
  flex-direction: column-reverse;
  border-radius: 20px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  z-index: 100;
}

.main-container .left {
  display: block;
  width: 100%;
  background-color: #fafafa;
  overflow: auto;
}

.main-container .left .content {
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
}

.main-container .right {
  background-image: url(./svlaw-haus.jpg);
  background-size: cover;
  background-position: 80% 20%;
  display: flex;
  width: 100%;
  height: 160px;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;
  overflow-y: auto;
}

.main-container
  .right
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ffffff;
}

.main-container
  .right
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.5);
}

.main-container
  .right
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.8);
}

.main-container
  .right
  .ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-icon {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.3);
}

.main-container
  .right
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon {
  background-color: transparent;
  border-color: #ffffff;
  color: rgba(255, 255, 255, 0.5);
}

.main-container
  .right
  .ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-content {
  min-height: 34px;
}

.main-container
  .right
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.main-container
  .right
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: rgba(255, 255, 255, 0.4);
}

.main-container .right img.svlaw-logo {
  width: 60%;
  height: auto;
  flex-grow: 0;
  margin-top: 32px;
}

.main-container .my-swiper-wrapper {
  height: calc(100%);
}

.main-container .my-swiper {
  height: calc(100% - 28px);
  box-shadow: inset 0px -10px 10px -12px rgba(0, 0, 0, 0.5);
}

.main-container .my-swiper-wrapper .ant-spin-container {
  height: calc(100% - 20px);
}

.main-container .my-swiper .swiper-slide {
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 24px;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.main-container .my-swiper .swiper-slide-active {
  visibility: visible;
}

.main-container .my-swiper .swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 3px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.4);
}

.privacy-footer-wrapper {
  justify-content: center;
  text-align: center;
  padding: 12px 48px;
}

@media screen and (max-width: 900px) {
  html,
  body {
    overflow-x: hidden;
    overflow-y: auto;
  }

  body {
    background-color: #faefd5;
    min-height: 110vh;
    justify-content: flex-start;
  }

  h1.onboarding-header {
    display: none;
  }

  h1 {
    text-align: justify;
  }

  h2 {
    text-align: justify;
    font-size: 0.8rem;
  }

  h3 {
    font-size: 0.8rem;
  }

  .main-container .right .svlaw-logo {
    max-width: 240px;
  }

  .main-container {
    width: 96vw;
    height: 85vh;
    margin-bottom: auto;
    margin-top: 12px;
  }

  .main-container .left {
    height: calc(100% - 80px);
  }

  .main-container .left .content {
    height: calc(100% - 40px);
  }

  .main-container .left .content p {
    font-size: 0.8rem;
    text-align: justify;
  }

  .main-container .right {
    justify-content: center;
    margin-top: 0;
    height: 80px;
  }
  .main-container .right .steps-nat,
  .main-container .right .steps-jur {
    display: none;
  }

  .main-container .right img.svlaw-logo {
    margin-top: 10px;
  }

  .privacy-footer p {
    font-size: 0.55rem;
  }

  .privacy-footer-wrapper {
    padding: 0 12px;
  }

  .main-container .my-swiper {
    height: calc(100% - 16px);
  }

  .main-container .my-swiper .swiper-slide {
    padding: 6px 0 24px 0;
    overflow-y: auto;
  }

  .content .ant-btn {
    font-size: 0.75rem;
    padding: 2px 4px;
  }

  .footer-controls {
    flex-wrap: nowrap;
    margin: 0;
    padding-top: 6px;
  }

  .content .ant-form .ant-form-item {
    margin-bottom: 12px;
  }

  .content .ant-form .ant-form-item-label > label {
    height: unset;
  }
}

@media screen and (min-width: 900px) {
  body:before,
  body:after {
    content: "";
    position: absolute;
    left: 0;
    min-width: 300vw;
    min-height: 300vw;
    background-color: #faefd5;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 100%;
  }

  body:before {
    bottom: 15vh;
    border-radius: 45%;
    animation-duration: 180s;
  }

  body:after {
    bottom: 12vh;
    opacity: 0.5;
    border-radius: 37%;
    animation-duration: 170s;
  }

  h1 {
    text-align: left;
    font-size: 1.8em;
  }

  h2 {
    text-align: justify;
    font-size: 1em;
    hyphens: auto;
  }

  h3 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
    text-align: justify;
    hyphens: auto;
  }

  .main-container {
    flex-direction: row;
    height: 90vh;
    max-height: 1200px;
  }

  .main-container .left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: block;
    width: 65%;
    height: 100%;
    background-color: #fafafa;
  }

  .main-container .left .content {
    justify-content: center;
    align-items: center;
    height: calc(100% - 62px);
    padding: 48px;
  }

  .main-container .right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-position: 80% center;
    width: 35%;
    height: 100%;
    padding: 24px;
  }

  .privacy-footer p {
    font-size: 0.75rem;
    padding: 0;
    margin: 0;
  }

  .content .ant-form-item-label > label {
    height: auto;
  }

  .content .ant-form .ant-form-item {
    margin-bottom: 22px;
  }

  .content .ant-form .ant-form-item-explain-success {
    font-size: 0.86em;
  }

  .footer-controls {
    margin: 12px 24px;
    flex-wrap: nowrap;
  }
}

.main-container .left .content .ant-carousel,
.main-container .left .content .ant-carousel .slick-slider {
  height: calc(100%);
}

.main-container .left .carousel .slick-list,
.main-container .left .carousel .slick-track,
.main-container .left .carousel .slick-slide,
.main-container .left .carousel .slick-slide > div {
  height: calc(100% - 24px);
}

.box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ant-carousel .ant-card {
  margin: 32px;
  border: 0;
}

.ant-carousel .carousel-dots {
  position: absolute !important;
  bottom: 10px;
  margin: 12px 0;
}

.ant-carousel .carousel-dots li button {
  background: #333333;
}

.ant-carousel .carousel-dots li.slick-active button,
.carousel-dots li.slick-active button {
  background: #333333;
}

.ant-card {
  border-radius: 12px;
}

.ant-upload.ant-upload-drag.uploader {
  border: 2px dashed #2a4f86;
  height: auto;
  border-radius: 12px;
}

.ant-upload.ant-upload-drag.uploader .icon {
  font-size: 42pt;
  margin: 24px 0;
  color: #2a4f86;
}

.ant-upload.ant-upload-drag.uploader .upload-text {
  font-size: 13pt;
  color: #333333;
  text-align: center;
}

.ant-upload.ant-upload-drag.uploader .upload-hint {
  font-size: 11pt;
  color: #333333;
  text-align: center;
}

.content .ant-form .ant-row.ant-form-item {
  margin-bottom: 8px;
}

.content .ant-form .ant-col.ant-form-item-label {
  padding: 0;
}
